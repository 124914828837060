Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";
exports.getSubscriptionPlanAPiEndPoint = "bx_block_custom_user_subs/subscriptions"
exports.checkoutApiEndPoint = "bx_block_payments/checkout";
exports.postMethodType = "POST";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";

exports.subscriptionPlan = "Subscription Plan";
exports.subscriptionPlanDescriptionBeforeLogin = 
"Please login to see your subscription plan"
exports.subscriptionPlanDescription = 
"Please subscribe to a plan for using DelegateMe";
exports.subscribeBtnText = "Subscribe";
exports.upgradeBtnText = "Upgrade";
exports.subscriptionDone = "Subscription Done!";
exports.subscriptionDoneDescription = 
"Your subscription has been successfully completed. Please continue to start creating your profile.";
exports.continueBtnText = "Continue";

exports.subscriptionFailed = "Subscription Failed!";
exports.subscriptionFailedDescription = 
"Your subscription failed due to payment failure . Please try again to start using ‘Memories’.";
exports.tryAgainBtnText = "Try Again";

exports.subscribeToPlanText = 
"Please subscribe to a plan in order to access the home section of your account";
// Customizable Area End
